import { Col, Row, Layout, Input, Dropdown, Menu, Divider, Avatar, Select } from "antd";
import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { SearchOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

const { Option } = Select
const TopNav = observer(({ data }) => {

  return (
    <Row style={{ backgroundColor: '#fff', width: '100%', minHeight: '10vh' }} className="t18_topnav">

    </Row>
  );
});
export default TopNav;
