import { Col, Collapse, Row } from "antd"
import { FilterRenderer } from "./FilterRenderer";
const { Panel } = Collapse;

const T18filter = ({ filters, onChange, columns, defaultFilterState }) => {



    return (
        <div><Collapse defaultActiveKey={[true ? '0' : '1']} style={{ marginBottom: 25 }}>

            <Panel header="Filters" key="0" style={{ backgroundColor: "#fafafa" }}>

                <Row gutter={[32, 24]} >
                    {columns.map((item, index) => {
                        var obj = {
                            number: 6,
                            bool: 6,
                            image: 24

                        }

                        if (item.filter) {

                            return <Col span={obj[item.type] || 6}>
                                <FilterRenderer data={item} onChange={(val) => { onChange(item.key, val) }} />
                            </Col>


                        }
                    })}

                </Row>
            </Panel>
        </Collapse></div>
    )


}

export default T18filter;