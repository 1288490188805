import { Button, Popconfirm } from "antd"
import { makeAutoObservable } from "mobx";
// import helper from "../../../dataLayer/fetchHelper"
import helper from 't18helper/dist/fetchHelper';
// import helper from 't18helper/src/fetchHelper';

import { generateRenders } from "../tableRenderers"

class t18CrudFlow {

    constructor() {
        makeAutoObservable(this, {
        });
    }

    name = ''
    reverse = true
    flags = {
        filter: true,
        table: true,
        form: true,
        defaultFilterState: true,
    }

    currentPage = 0
    setCurrentPage(page) {
        this.currentPage = page - 1
    }

    setFlag(flag, value) {
        this.flags[flag] = value
    }

    tableFlags = {
        edit: true,
        view: true,
        delete: true,
        create: true,
        bulkCreate: true,
        bulkDownload: true,
    }

    setTableFlag(flag, value) {
        this.tableFlags[flag] = value
    }

    setConfig(config) {
        //loop through config and set 
        for (let key in config) {
            this[key] = config[key]
        }
    }

    filters = {}
    setFilter(filter, value) {


        this.filters[filter] = value
        console.log(filter, value, 'filters')
        this.getData()
    }

    data = [];
    limit = 1000;
    skip = 0;

    setLimit = (limit) => this.limit = limit;
    setSkip = (skip) => this.skip = skip;

    getData = async () => {
        this.data = []
        var res = await helper.filterData(this.name, {
            ...this.filters,
            limit: this.limit,
            skip: this.skip,
        });
        if (this.reverse) {
            res.res = res.res.reverse()
        }
        if (res.status === 200) {
            this.data = res.res;
        } else {
            console.log(res)
            helper.message.error('error fetching data')
        }
        return res;

    }

    getNextPage = async () => {

    }

    deleteKey = "_id";
    setDeleteKey(key) {
        this.deleteKey = key
    }
    deleteData = async (index) => {
        if (this.data.length === 0) return helper.message.error('No data to delete');
        let id = this.data[index]['_id']
        // this.data = this.data.filter((item, i) => i !== index)

        if (!id) return helper.message.error('no data to delete')
        let res = await helper.createData({ id }, this.name)
        if (res.status === 200) {
            helper.message.success('deleted successfully')
            await this.getData()
            return res
        }
        helper.message.error('error deleting data')
        return res
    }


    columns = [];
    tableColumns = [];
    setColumns(columns) {
        this.columns = columns
        var temp = generateRenders(columns);
        if (this.tableFlags.edit) {
            temp.push({
                title: 'Edit',
                key: 'edit',
                render: (text, record) => (
                    <Button onClick={() => {
                        this.showEditData(record)

                    }}>Edit</Button>
                ),
                showInTable: true,
                disableInForm: true,
            })
        }

        if (this.tableFlags.view && !this.tableFlags.edit) {
            temp.push({
                title: 'View',
                key: 'view',
                render: (text, record, index) => (
                    <Button onClick={() => {
                        console.log(record, 'record')
                        this.viewData(index + this.currentPage * 10)
                    }}>View More
                        {/* {index + this.currentPage * 10} */}
                    </Button>
                ),
                showInTable: true,
                disableInForm: true,
            })
        }

        if (this.tableFlags.delete) {
            temp.push({
                title: 'Delete',
                key: 'delete',
                render: (text, record, index) => (
                    <Popconfirm
                        title={`Are you sure to delete this ${this.name} ?`}
                        onConfirm={
                            () => {
                                console.log(index, 'index')
                                this.deleteData(index)
                            }
                        }
                        // onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button onClick={() => { }}>Delete</Button>
                    </Popconfirm>
                ),
                showInTable: true,
                disableInForm: true,
            })
        }






        this.tableColumns = temp;
        console.log(this.tableColumns, 'tableColumns')
        this.getData()
    }


    viewModal = false;
    setViewModal(value) {
        this.viewModal = value
    }
    viewData(index) {
        this.setSelectedDataIndex(index)
        this.viewModal = true;
    }

    editModal = false;
    setEditModal(value) {
        this.editModal = value
    }
    showEditData = async (index) => {
        this.setSelectedDataIndex(index)
        this.editModal = true;
    }

    selectedDataIndex = -1;
    setSelectedDataIndex(index) {
        this.selectedDataIndex = index
        console.log(this.data, index, 'selectedDataIndex')
    }




}


export default t18CrudFlow;