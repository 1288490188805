import { Col, Row, Layout, Input, Select, DatePicker, Tabs, Button } from 'antd'
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react'
import helper from 't18helper/dist/fetchHelper';
import ChefPaymentsTab from './ChefPaymentsTab';

import OrderTab from "./OrderTab";
import PortfolioTab from './PortfolioTab';
const { TabPane } = Tabs;

var box = {
    minHeight: 100,
    backgroundColor: "white",
    margin: 10,
    padding: 30,
    borderRadius: 10
};
const { Option } = Select;

const ChefPayments = observer(({ props }) => {
    return (
        <>
            <Row gutter={[8, 8]} style={{ width: '100%' }}>
                <Col span={24}>

                    <div style={box}>
                        <div style={{ height: 50, width: '100%' }}>
                            <Button onClick={async () => {
                                // alert('hello')
                                var res = await helper.createData({}, 'calculateChefpayments')
                                if (res.status === 200) {
                                    window.location.reload();

                                }

                            }} style={{ float: 'right' }}>Generate Payments</Button>
                        </div>
                        <Tabs defaultActiveKey="1"  >

                            <TabPane tab="Payments" key="1"> <ChefPaymentsTab /></TabPane>
                        </Tabs>
                    </div>
                </Col>

            </Row>
        </>
    );
});
export default ChefPayments;