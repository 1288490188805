import { Collapse, Table } from "antd"
import { generateRenders } from "../tableRenderers"

import t18CrudFlow from "./t18CrudFlow";
import { useEffect } from "react";
import { observer } from "mobx-react";
import T18filter from "../T18Filter";
import Modal from "antd/lib/modal/Modal";
import T18Form from "../T18Form";
import T18View from "../T18View";

const data = new t18CrudFlow();

const T18Crud = observer(({ obj }) => {
    useEffect(() => {

        data.setConfig({
            name: obj.name,

        })
        data.setTableFlag('edit', false)
        data.setTableFlag('view', true)
        data.setTableFlag('delete', false)
        // data.setFlag('defaultFilterState', true)
        data.setColumns(obj.columns);

    }, [])



    const processedColumns = generateRenders(obj.columns)
    return (
        <div style={{ width: '100%', overflow: 'scroll' }}>

            {data.flags.filter && <><T18filter columns={data.columns} defaultFilterState={data.defaultFilterState} onChange={(key, val) => { data.setFilter(key, val) }} /></>}
            {data.flags.table && <Table columns={data.tableColumns}
                dataSource={data.data} onChange={(selectedRowKeys, selectedRows, info) => {
                    // alert(JSON.stringify(selectedRowKeys))
                    data.setCurrentPage(selectedRowKeys.current)
                }} />}
            <Modal
                width={1000}
                title=" Details" visible={data.viewModal}
                onOk={() => { data.setViewModal(false) }}
                onCancel={() => { data.setViewModal(false) }}
                // okButtonProps={{ disabled: true, hidden: true }}
                cancelButtonProps={{ disabled: true, hidden: true }}
            >

                <T18View columns={data.columns} data={data.data[data.selectedDataIndex]} />

            </Modal>

            <Modal
                width={800}
                title="Edit Details" visible={data.editModal}
                onOk={() => { data.setEditModal(false) }}
                cancelButtonProps={{ disabled: true, hidden: true }}
            >
                <T18Form columns={data.columns} />
            </Modal>
        </div>
    )
})

export default T18Crud