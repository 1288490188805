import { Button, Popconfirm } from "antd";
import { makeAutoObservable } from "mobx";
import helper from 't18helper/dist/fetchHelper';
var obj = {
    "name": "users",
    "collection": false,
    "displayConfig": {
        "view": false,
        "edit": true,
        "createNew": false,
        "showTable": true,
        "bulkCreate": false,
        "filters": false
    },
    "columns": [
        {
            "title": "User Id",
            "dataIndex": "id",
            "key": "id",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
        },
        {

            "title": "Name",
            "dataIndex": "name",
            "key": "name",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": false,
            "optional": true,
        },
        {
            "title": "Email Id",
            "dataIndex": "email",
            "key": "email",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": false,
            "optional": true,
        },
        {
            "title": "Phone",
            "dataIndex": "phoneNo",
            "key": "phoneNo",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": false,
            "optional": true,
        },
        {
            "title": "Profile Pic",
            "dataIndex": "profilePic",
            "key": "profilePic",
            "showInTable": false,
            "type": "object",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                secure: true,
            },
            fieldsType: {

                url: 'image'

            },

            // "render": (val, record) => {

            //     return <Image src={val.url} />
            // }


        },
        {
            "title": "Account Status",
            "dataIndex": "status",
            "key": "status",
            "showInTable": true,
            "type": "enum",
            "filter": true,
            "secure": false,
            "disableInForm": false,
            "optional": true,
            "arr": ['enabled', 'disabled'],
            'colors': { enabled: 'green', disabled: 'red' }


        },

        {
            "title": "Address",
            "dataIndex": "defaultAddress",
            "key": "defaultAddress",
            "showInTable": false,
            "type": "object",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                index: true,
                latitude: true,
                longitude: true,
                location: true,
                locality: true,
                county: true,
            },
            fieldsType: {}


        },

        {
            "title": "Action",
            "dataIndex": "action",
            "key": "action",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            disableInView: true,
            "optional": true,
            "render": (val, record) => {

                return (
                    <>
                        {(record.status == "disabled") && <Popconfirm
                            title="Are you sure want to enable?"
                            onConfirm={async () => {
                                console.log("clicked", record._id);
                                let res = await helper.updateData({ _id: record._id, "status": "enabled" }, "users")
                                console.log(res)
                                if (res.status === 200) {
                                    window.location.reload();

                                    // helper.message.success('approved successfully')
                                    // await this.getData()
                                    return res
                                }
                                return helper.message.error('error enabling user')

                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        ><Button type="primary" size="small" style={{ borderColor: "green", color: 'green' }} ghost
                        >Enable</Button></Popconfirm>}

                        {(record.status == "enabled" || !record.status) && <Popconfirm
                            title="Are you sure to disable?"
                            onConfirm={async () => {
                                console.log("clicked", record._id);
                                let res = await helper.updateData({ _id: record._id, "status": "disabled" }, "users")
                                console.log(res)
                                if (res.status === 200) {
                                    window.location.reload();

                                    // helper.message.success('approved successfully')
                                    // await this.getData()
                                    return res
                                }
                                return helper.message.error('error disabling user')

                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        ><Button type="primary" size="small" style={{ borderColor: "red", color: 'red' }} ghost
                        >Disable</Button></Popconfirm>}
                    </>

                );
            }
        },
    ]

}

export { obj }