import { useEffect } from 'react'
import { observer } from 'mobx-react';
import { dbstore } from '../../dataLayer/dbStore';
import CRUDComp from '../CRUDComp';
import { obj } from "../../configs/UserTab";
import T18Crud from '../common/t18Crud/T18Crud';

var columns = obj.columns;
var displayConfig = obj.displayConfig;

const UserTab = observer(({ props }) => {

    return (
        <T18Crud obj={obj} />

        // <CRUDComp name="user" columns={columns} data={dbstore.user} {...displayConfig} />

    );
});
export default UserTab;