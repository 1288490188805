import { Button, Popconfirm } from "antd";
import { makeAutoObservable } from "mobx";
import moment from "moment";
import helper from 't18helper/dist/fetchHelper';
var getStringFromSlot = (slot) => {
    let slotNum = slot;
    if (!slot || slot < 0) return "";
    if (slotNum === 24) slotNum = 12;
    let string = slotNum > 12 ? slotNum - 12 + ' PM - ' + (slotNum - 12 + 1) + ' PM' : slotNum + ' AM - ' + (slotNum + 1) + ' AM';
    if (slotNum == 12) {
        string = '12 PM - 1 PM'
    }
    if (slotNum == 23) {
        string = '11 PM - 12 AM'
    }
    console.log(string, slot, 'string');
    return string;
}
class WishTab {
    "name" = "wishes"
    "collection" = false
    "displayConfig" = {
        "view": false,
        "edit": true,
        "createNew": true,
        "showTable": true,
        "bulkCreate": true,
        "filters": true
    }
    "columns" = [
        {
            "title": "Wish Id",
            "dataIndex": "_id",
            "key": "_id",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
        },
        {
            "title": "User-Name",
            "dataIndex": "userId",
            "key": "userId",
            "showInTable": false,
            "type": "select",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            disableInView: true,
            "optional": true,
            "arr": [],

            onSearchFilter: async (val) => {
                if (val == '') return;
                console.log(val, 'onSearchFilter');
                var res = await helper.filterData("users", {
                    name: val,
                    limit: 100,
                });
                var names = res.res.map(item => {
                    return item['name'];
                })
                var values = res.res.map(item => {
                    return item['_id'];
                })
                console.log(names)
                this.columns[1].arr = names
                this.columns[1].values = values
            },
        },
        {
            "title": "User Name",
            "dataIndex": "userInfo",
            "key": "userInfo",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "optional": true,
            "disableInView": false,

            fieldsType: {},
            render: function (text, record) {
                return text?.name
            }


        },
        {
            "title": "Status",
            "dataIndex": "status",
            "key": "status",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
        },



        {
            "title": "Min Price",
            "dataIndex": "minPrice",
            "key": "minPrice",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "render": (val, record) => {
                val = val.toFixed(2)
                // console.log(val)
                return <p>£ {val}</p>
            }
        },
        {
            "title": "Max Price",
            "dataIndex": "maxPrice",
            "key": "maxPrice",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "render": (val, record) => {
                val = val.toFixed(2)
                // console.log(val)
                return <p>£ {val}</p>
            }
        },
        {
            "title": "Deliver On",
            "dataIndex": "deliverOn",
            "key": "deliverOn",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            render: function (text, record) {
                return moment(text).format('MMM Do YYYY');
            }
        },
        {

            "title": "Delivery Slot",
            "dataIndex": "deliverySlot",
            "key": "deliverySlot",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "colors": [{ "enum1": "red", "enum2": "blue" }],
            // "children": [{}, {}, {}],
            // "default": "String -> Any",
            // "arr": ["val1", "val2", "val3"],
            // "values": ["id1", "id2", "id3"],
            "render": (val) => getStringFromSlot(val)

        },
        {
            "title": "Quantity",
            "dataIndex": "quantity",
            "key": "quantity",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,

        },

        {
            "title": "Unit",
            "dataIndex": "units",
            "key": "units",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,


        },

        // {
        //     "title": "Allergy",
        //     "dataIndex": "note",
        //     "key": "note",
        //     "showInTable": false,
        //     "type": "string",
        //     "filter": false,
        //     "secure": false,
        //     "disableInForm": true,
        //     "optional": true,

        // },


        {
            "title": "Item Name",
            "dataIndex": "items",
            "key": "items",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            render: function (text, record) {
                return <p>{record?.items?.name}</p>
            }
        },
        {
            "title": "Item Description",
            "dataIndex": "items",
            "key": "items",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            render: function (text, record) {
                return <p>{record.items.description}</p>
            }
        },


        {
            "title": "Action",
            "dataIndex": "Action",
            "key": "deliverOn",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            disableInView: true,
            "optional": true,
            "render": (val, record) => {

                return (
                    <>
                        {record.adminStatus != "APPROVED" && <Popconfirm
                            title="Are you sure to approve?"
                            onConfirm={async () => {
                                console.log("clicked", record._id);
                                let res = await helper.updateData({ _id: record._id, "adminStatus": "APPROVED" }, "wishes")
                                console.log(res)
                                if (res.status === 200) {
                                    window.location.reload();

                                    // helper.message.success('approved successfully')
                                    // await this.getData()
                                    return res
                                }
                                return helper.message.error('error approving data')

                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        ><Button type="primary" size="small" style={{ borderColor: "green", color: 'green' }} ghost
                        >Approve</Button></Popconfirm>}
                    </>

                );
            }
        },

        {
            "title": "User Info",
            "dataIndex": "userInfo",
            "key": "userInfo",
            "showInTable": false,
            "type": "object",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                _id: true,
                cuisineTypes: true,
                age: true,
                gender: true,
                description: true

            },
            fieldsType: {}


        },
        {
            "title": "Delivery Address",
            "dataIndex": "deliveryAddress",
            "key": "deliveryAddress",
            "showInTable": false,
            "type": "object",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                index: true,
                latitude: true,
                longitude: true,
                location: true,
                locality: true,
                county: true,
            },
            fieldsType: {}


        },

    ]

}
var obj = new WishTab()
makeAutoObservable(obj)

export { obj }