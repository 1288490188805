import { Card, Col, Collapse, Row } from "antd"
import { observer } from "mobx-react";
import { FilterRenderer } from "./FilterRenderer";
import { FormRenderer } from "./FormRenderer";
import { ViewRenderer } from "./ViewRenderer";
const { Panel } = Collapse;
var obj = {
    number: 6,
    bool: 6,
    image: 24,
    string: 12,
    object: 24,
    array: 24

}
const T18View = observer(({ filters, onChange, columns, data }) => {



    return (
        <div>

            <Row gutter={[32, 24]} style={{ padding: 30 }} >
                {columns.map((item, index) => {
                    // if (item.render) {
                    //     data[item.dataIndex] = item.render(data[item.dataIndex])
                    // }

                    if (item.disableInView) {
                        return null
                    }


                    return <Col span={obj[item.type] || 6}>



                        <ViewRenderer item={item} data={data} />

                    </Col>



                })}
            </Row>
        </div>
    )


})

export default T18View;