
import { NavLink } from "react-router-dom";


const T18NavLink = ({ to, title, Icon, highlight = true }) => {
  // console.log({ to, title, iconName, iconType, otherProps });
  return (
    <NavLink
      to={to}
      className="t18_nav_route"
      activeClassName="t18_nav_route_active"
      exact={true}



    >
      <span>
        {title}
      </span>
    </NavLink>
  );
};

export default T18NavLink;
