import { useEffect } from 'react'
import { observer } from 'mobx-react';
import { dbstore } from '../../dataLayer/dbStore';
import CRUDComp from '../CRUDComp';
import { obj } from "../../configs/BaseTab"; // to be replaced
import T18Crud from '../common/t18Crud/T18Crud';

var columns = obj.columns;
var displayConfig = obj.displayConfig;

const BaseTab = observer(({ props }) => { // replace
    useEffect(() => {
        // dbstore.getData('base'); // replace
    }, [])
    return (

        <T18Crud obj={obj} />

        // <CRUDComp name="base" columns={columns} data={dbstore.base} {...displayConfig} /> //replace

    );
});
export default BaseTab; //replace