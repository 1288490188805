

import { Col, Row, Layout, Input, Select, DatePicker, Tabs, Table, Tag, Space, Switch, Button, Modal, message, Collapse } from 'antd'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { observer } from 'mobx-react';
import { toJS } from 'mobx';
import { dbstore } from '../dataLayer/dbStore';
import { FormRenderer } from './common/FormRenderer';
import { generateRenders } from "./common/tableRenderers"
import _ from 'lodash';
import { object as _object } from 'underscore';
import { CSVLink, CSVDownload } from "react-csv";
import CSVReader from 'react-csv-reader'
import { FilterRenderer } from './common/FilterRenderer';
const csv = require('csvtojson')

const { Panel } = Collapse;
var box = {
    minHeight: 100,
    backgroundColor: "white",
    margin: 10,
    padding: 0,
    borderRadius: 10
};

const ArrComp = observer(({ value, item, handleFormChange, view = false }) => {
    var obj = {
        number: 6,
        bool: 6,
        image: 24

    }
    const [arrSize, setarrSize] = useState(2)
    const [arr, setarr] = useState(value[item.key] || [])
    useEffect(() => {
        console.log('gege')
        if (value[item.key]) {
            // console.log({ ...dataObj });
            setarr(value[item.key])
        } else {
            setarr([])
        }

    }, [value])


    const handleArrChange = (key, val) => {
        var temp = [...arr];
        _.set(temp, key, val)
        console.log(temp)
        handleFormChange(item.key, temp)
        setarr(temp);
    }

    return (<Col span={24} style={{}}>
        <h2>{item.title}</h2>
        {arr.map((a, j) => {
            return (<Row style={{ marginTop: 10, backgroundColor: '#efeff3', padding: 10 }}>
                <Col span={21}>

                    <Row gutter={[24, 24]}>
                        {item.children.map((child, i) => {
                            var disabled = []
                            if (child.type == "select") {
                                if (child.selectType == "unique") {
                                    disabled = _.map(arr, child.key)
                                }
                            }
                            return <Col span={obj[child.type] || 12}>
                                <FormRenderer view={view} data={child} onChange={(val) => { handleArrChange(`${j}.` + child.key, val) }} value={arr[j][child.key || null]} disabled={disabled} />
                            </Col>
                        })}
                    </Row>

                </Col>

                <Col span={3} style={{ justifyContent: 'center', alignItems: 'center', display: 'flex' }} >
                    {!view && <Button style={{ marginTop: 20 }} onClick={() => { var temp = [...arr]; temp.splice(j, 1); handleFormChange(item.key, temp); setarr(temp) }} danger>Delete</Button>}
                </Col>
            </Row>)
        })}

        {!view && <Button style={{ marginTop: 20, width: '40%' }} onClick={() => { var temp = [...arr]; temp.push({}); handleFormChange(item.key, temp); setarr(temp) }} danger>Add +</Button>}

    </Col>)
})
const CRUDComp = observer(forwardRef((props, ref) => {
    var { heading = null, columns, data, name, edit, createNew = true, showTable = true, submit = null, onChange = () => { }, successCB = () => { }, bulkCreate = true, filters = true, view = false } = props
    const [createModal, setcreateModal] = useState(false)
    const [dataObj, setdataObj] = useState({})
    const [createMode, setcreateMode] = useState(true)
    const [filterObj, setfilterObj] = useState({})
    var processedColumns = generateRenders(columns)
    if (edit) {
        processedColumns.push(
            {
                title: 'Edit',
                key: 'edit',
                render: (text, record) => (
                    <Button onClick={() => { setdataObj({ ...record }); console.log({ ...record }); setcreateMode(false); setcreateModal(true) }}>Edit</Button>
                ),
                showInTable: true,
                disableInForm: true,

            })
    }
    if (view && !edit) {
        processedColumns.push(
            {
                title: 'View',
                key: 'view',
                render: (text, record) => (
                    <Button onClick={() => { setdataObj({ ...record }); console.log({ ...record }); setcreateMode(false); setcreateModal(true) }}>View</Button>
                ),
                showInTable: true,
                disableInForm: true,

            })

    }


    useImperativeHandle(ref, () => ({

        getAlert() {
            alert("getAlert from Child");
        },

        setCreateModal: (val) => { setcreateModal(val) },
        setdataObj: (val) => { console.log('setting data obj', dataObj, val); setdataObj({ ...val }) }

    }));

    const handleFormChange = (key, val) => {
        var temp = { ...dataObj };
        _.set(temp, key, val)
        console.log(temp)
        onChange(temp)
        setdataObj(temp);
    }

    const handleFilterChange = (key, val) => {
        var temp = { ...filterObj };
        _.set(temp, key, val)
        console.log(temp)
        dbstore.filterData(name, temp)
        setfilterObj(temp);
    }

    const validateForm = async () => {
        if (view && !createMode) {
            setcreateModal(false)

            return
        }
        var temp = { ...dataObj }

        for (let i = 0; i < columns.length; i++) {
            const key = columns[i].key;
            // console.log(key)
            var op = false;
            if (columns[i].optionalFunc) {
                op = columns[i].optionalFunc(temp)
            }
            if (!(key in temp)) {
                if ("default" in columns[i]) {
                    if (columns[i].default != []) {
                        temp[key] = columns[i].default
                    }
                }
            }

            if (!(key in temp) && !columns[i].optional && !columns[i].disableInForm && !op) {
                message.error('Please Enter ' + columns[i].title);
                return
            }

        }
        if (createMode) {
            if (submit) {
                submit(dataObj)

            } else {
                var images = _.filter(columns, { type: 'image' });
                // var temp = { ...dataObj }
                for (var i = 0; i < images.length; i++) {
                    var res = await dbstore.generateAndUpload(dataObj[images[i].key], images[i].secure)
                    // console.log(res, ' upload response', dataObj[images[i].key])
                    temp[images[i].key] = res
                }
                // console.log(temp, ' here', images)
                var result = await dbstore.create(temp, name);
                if (result == 200) {
                    setcreateModal(false)

                    successCB(temp)
                }


            }

        } else {
            if (submit) {
                submit(dataObj)

            } else {
                var images = _.filter(columns, { type: 'image' });
                var temp = { ...dataObj }
                for (var i = 0; i < images.length; i++) {
                    if (dataObj[images[i].key] instanceof File) {
                        var res = await dbstore.generateAndUpload(dataObj[images[i].key], images[i].secure)
                        // console.log(res, ' upload response', dataObj[images[i].key])
                        temp[images[i].key] = res
                    }
                }

                var result = await dbstore.update(temp, name);
                if (result == 200) {
                    setcreateModal(false)
                    successCB(temp)
                }

            }
        }
    }

    return (
        <>
            {showTable && <Row gutter={[8, 8]} style={{ width: '100%' }}>
                <Col span={24}>
                    <div style={box}>
                        <Row style={{ marginBottom: 10 }} >
                            <Col span={12}>
                                {createNew &&
                                    <Button type="primary" size="large" onClick={() => { setdataObj({}); setcreateModal(true) }}>+ Create New {name}</Button>}
                            </Col>
                            <Col span={12}>

                                {bulkCreate && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                    <Row>
                                        <CSVReader cssClass="t18_upload" cssInputClass="t18_upload_input" cssLabelClass="t18_upload_label" label="Bulk Create"
                                            onFileLoaded={async (data, fileInfo) => {
                                                const removeFields = (arr, keys) => {
                                                    var o = _object(keys, arr)
                                                    for (var i = 0; i < columns.length; i++) {
                                                        if (columns[i].disableInForm == true) {
                                                            delete o[columns[i].key]
                                                        } else {
                                                            if (columns[i].type == "multiSelect") {
                                                                o[columns[i].key] = o[columns[i].key]?.split(',')
                                                            } else if (columns[i].type == "bool") {
                                                                o[columns[i].key] = o[columns[i].key] == "TRUE"

                                                            }

                                                        }
                                                    }
                                                    var requiredKeys = _.map(columns, 'key');
                                                    var objKeys = Object.keys(o);
                                                    for (var i = 0; i < objKeys.length; i++) {
                                                        var index = requiredKeys.indexOf(objKeys[i])
                                                        if (index == -1) {
                                                            delete o[objKeys[i]]
                                                        }

                                                    }
                                                    return o;
                                                }
                                                var keys = data[0]

                                                for (let i = 1; i < data.length; i++) {
                                                    var obj = data[i];
                                                    obj = removeFields(obj, keys);
                                                    console.log(obj)
                                                    var result = await dbstore.create(obj, name);
                                                    if (result == 200) {
                                                        message.success("Created item " + i + " successfully")
                                                    }
                                                }
                                            }} />

                                        {/* <CSVReader cssClass="t18_upload" cssInputClass="t18_upload_input" cssLabelClass="t18_upload_label" label="Bulk Edit" onFileLoaded={(data, fileInfo) => console.dir(data, fileInfo)} /> */}
                                        <CSVLink data={data} filename={name + "_data.csv"}><span className="t18_download"> Download CSV</span></CSVLink>
                                    </Row>
                                </div>}</Col></Row>
                        {filters &&
                            <div><Collapse defaultActiveKey={['1']} style={{ marginBottom: 25 }}>

                                <Panel header="Filters" key="0" style={{ backgroundColor: "#fafafa" }}>

                                    <Row gutter={[32, 24]} >
                                        {/* <h2 style={{ width: '100%', marginBottom: -20 }}>Filters</h2> */}
                                        {/* <Col span={24} style={{ backgroundColor: 'red' }}></Col> */}
                                        {columns.map((item, index) => {
                                            var obj = {
                                                number: 6,
                                                bool: 6,
                                                image: 24

                                            }

                                            if (item.filter) {

                                                return <Col span={obj[item.type] || 6}>
                                                    <FilterRenderer edit={!createMode} data={item} onChange={(val) => { handleFilterChange(item.key, val) }} value={filterObj[item.key]} />
                                                </Col>


                                            }
                                        })}

                                    </Row>
                                </Panel>
                            </Collapse></div>

                        }
                        {/* {bulkCreate &&
                            <div style={{ marginBottom: 20, marginTop: 5, display: 'flex', justifyContent: 'flex-end' }}>

                                <CSVReader cssClass="t18_upload" cssInputClass="t18_upload_input" cssLabelClass="t18_upload_label" label="Bulk Create"
                                    onFileLoaded={async (data, fileInfo) => {
                                        const removeFields = (arr, keys) => {
                                            var o = _object(keys, arr)
                                            for (var i = 0; i < columns.length; i++) {
                                                if (columns[i].disableInForm == true) {
                                                    delete o[columns[i].key]
                                                } else {
                                                    if (columns[i].type == "multiSelect") {
                                                        o[columns[i].key] = o[columns[i].key]?.split(',')
                                                    } else if (columns[i].type == "bool") {
                                                        o[columns[i].key] = o[columns[i].key] == "TRUE"

                                                    }

                                                }
                                            }
                                            var requiredKeys = _.map(columns, 'key');
                                            var objKeys = Object.keys(o);
                                            for (var i = 0; i < objKeys.length; i++) {
                                                var index = requiredKeys.indexOf(objKeys[i])
                                                if (index == -1) {
                                                    delete o[objKeys[i]]
                                                }

                                            }
                                            return o;
                                        }
                                        var keys = data[0]

                                        for (let i = 1; i < data.length; i++) {
                                            var obj = data[i];
                                            obj = removeFields(obj, keys);
                                            console.log(obj)
                                            var result = await dbstore.create(obj, name);
                                            if (result == 200) {
                                                message.success("Created item " + i + " successfully")
                                            }
                                        }
                                    }} />

                                <CSVLink data={data} filename={name + "_data.csv"}><span className="t18_download"> Download CSV</span></CSVLink>


                            </div>} */}



                        <Table columns={processedColumns} dataSource={data} />
                    </div>
                </Col>

            </Row>}
            <Modal
                title={heading ? heading : (createMode ? "Create New " + name : !view ? "Edit " : "View " + name)}
                style={{ top: 20 }}
                visible={createModal}
                centered
                width="50%"

                onOk={() => { validateForm() }}
                onCancel={() => { setcreateModal(false); setcreateMode(true) }}
            >
                <Row gutter={[32, 24]} style={{ margin: 15 }}>
                    {columns.map((item, index) => {
                        var obj = {
                            number: 6,
                            bool: 6,
                            image: 24

                        }

                        if (!item.disableInForm) {
                            if (item.type == 'object') {
                                return (<Col span={24} style={{ padding: 10, backgroundColor: '#fafafa' }}>
                                    <h2>{item.title}</h2>
                                    <Row gutter={[24, 24]}>
                                        {item.children.map((child, i) => {
                                            return <Col span={obj[child.type] || 12}>
                                                <FormRenderer view={view && !createMode} data={child} onChange={(val) => { handleFormChange(item.key + '.' + child.key, val) }} value={dataObj[item.key] ? dataObj[item.key][child.key] : columns[item.key]?.default} />
                                            </Col>
                                        })}
                                    </Row>
                                </Col>)
                            } else if (item.type == 'array') {
                                return <ArrComp value={dataObj} view={view && !createMode} handleFormChange={handleFormChange} item={item} />

                            } else {
                                return <Col span={obj[item.type] || 12}>
                                    <FormRenderer view={view && !createMode} edit={!createMode} data={item} onChange={(val) => { handleFormChange(item.key, val) }} value={dataObj[item.key] ? dataObj[item.key] : item.default || null} disable={item.disable} />
                                </Col>
                            }

                        }
                    })}
                </Row>
            </Modal>
        </>
    );
}));
export default CRUDComp;