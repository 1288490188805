import { InputNumber, Input, Switch, Upload, Select, Image, Row, Col, DatePicker, Card } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { dbstore } from '../../dataLayer/dbStore';
import { useEffect, useState } from 'react'
import moment from 'moment';

const { Dragger } = Upload;
const { Option } = Select;

const ViewRenderer = ({ arrayIndex = null, item, data }) => {
    const [uploadUrl, setuploadUrl] = useState(null)
    const ObjectRenderer = ({ obj, disabled = {}, fieldsType, render = {} }) => {
        console.log(obj, 'obj')

        useEffect(() => {
            console.log(obj, 'obj')
            // alert(obj)

            for (item in obj) {

            }
        }, [])

        return (<>
            {Object.keys(obj).map((item, index) => {
                if (!fieldsType) {
                    fieldsType = {}
                }
                var data = obj[item]
                if (disabled[item] != true) {
                    var Comp;
                    if (!obj[item]) {
                        return <></>
                    }

                    if (render[item]) {
                        // alert(JSON.stringify(obj[item]))

                        data = render[item](obj[item])

                        // alert(JSON.stringify(obj[item]))
                        // alert(JSON.stringify(data))
                        // delete render[item]

                    }



                    switch (fieldsType[item]) {
                        case "image":
                            Comp = <>
                                <Image
                                    style={{ padding: 20, alignSelf: 'center' }}
                                    width={200}
                                    src={data}
                                />

                            </>
                            break
                        case "pdf":
                            Comp = <>
                                <a target="_blank" href={data} ><Image
                                    preview={false}
                                    style={{ padding: 20, alignSelf: 'center' }}
                                    width={200}
                                    src={data}
                                /></a>

                            </>
                            break
                        case "enum":
                            Comp = <></>
                            break
                        default:

                            Comp = <Col span={8}> <Card title={item.toUpperCase()}>{
                                //type of obj[item] not object or array
                                typeof data === 'object' ? <Row gutter={[12, 12]}><ObjectRenderer obj={data} disabled={disabled[item]} fieldsType={fieldsType[item]} render={item.render} /> </Row> : data


                            }</Card></Col>
                            break;


                    }

                    return Comp
                }
            })}

        </>
        )

    }
    // alert(item.key)

    if (item.disableInView) {
        return <></>
    }
    if (!data[item.key]) {
        return <></>
    }
    var Comp;
    switch (item.type) {

        case "bool":
            Comp = <></>
            break;
        case "image":
            Comp = <>
                <Card title={item.title}>
                    <Image
                        width={200}
                        src={data[item.key]}
                    />
                </Card>

            </>
            break
        case "object":
            Comp = <Card title={arrayIndex != null ? '' : item.title} style={{ backgroundColor: '#eee' }}>
                <Row gutter={[32, 24]} style={{ justifyContent: 'center', alignItems: 'center' }}>
                    <ObjectRenderer obj={arrayIndex != null ? data[item.key][arrayIndex] : data[item.key]} disabled={item.disableFields || {}} fieldsType={item.fieldsType} render={item.render} />
                    {/* <span>{JSON.stringify(arrayIndex)} ii</span> */}
                </Row>
            </Card>
            break

        case "array":
            // item.type = "object"
            var newItem = { ...item }
            newItem.type = "object"
            // alert(JSON.stringify(newItem))
            // alert(JSON.stringify(data[item.key]))
            // alert(data[item.key].length)
            Comp = <Card title={item.title} style={{ backgroundColor: '#eee', width: 900 }}>
                {data[item.key].map((item1, index) => {
                    return <Col span={24} style={{ justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <Card style={{ margin: 5 }}>
                            <div style={{ backgroundColor: '#000', height: 20, width: 20, borderRadius: 20, marginBottom: 10, justifyContent: 'center', alignItems: 'center', }}>
                                <p style={{ textAlign: 'center', color: "#fff" }}> {index + 1}</p>
                            </div>
                            <ViewRenderer arrayIndex={index} item={newItem} data={data} />
                        </Card>
                    </Col>
                })}

            </Card>
            break
        case "enum":
            Comp = <></>
            break
        default:
            var Render = item.render;
            Comp = <>
                {!item.render ? <Card title={item.title}>{
                    data[item.key]

                }</Card> : <><Card title={item.title}>
                    {Render(data[item.key], data)}
                </Card></>}
            </>
            break;


    }
    return Comp
}

export { ViewRenderer };