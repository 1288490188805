import { Col, Row, Layout, Input, Select, DatePicker, Tabs } from 'antd'
import { useEffect, useState } from 'react'

import { TextField, Button, Grid } from '@material-ui/core';
import LoginImg from '../../assets/LoginImg.png';
import Logo from '../../assets/Logo.png';
import { dbstore } from '../../dataLayer/dbStore';

const Login = ({ name = "APP", primaryColor = "#E31F0E" }) => {
    const [data, setdata] = useState({})

    const validate = () => {
        if (!data.email || data.email == '') {
            alert("Please enter email")
            return false
        }
        if (!data.password || data.password == '') {
            alert("Please enter password")
            return false
        }

        return true

    }
    const handleSubmit = () => {
        var check = validate()
        if (check) {
            dbstore.loginFunc(data.email, data.password)
        }

    }
    const handleChange = (key, val) => {
        var temp = { ...data };
        temp[key] = val;
        setdata(temp);
        console.log(temp, data)
    }
    return (
        <>
            <Grid container alignItems="center" style={{ minHeight: "100vh", padding: "5em", overflowX: "hidden", fontSize: 16 }}>
                <Row gutter={[8, 8]} style={{ justifyContent: 'center', alignItems: 'center', flex: 1 }}>
                    {/* <Col span={24} style={{ justifyContent: 'center', alignItems: 'center' }} > */}
                    <img style={{ maxWidth: "20%" }} src={Logo}></img>
                    <h1 style={{ width: '100%', textAlign: 'center' }}>{name} Login</h1>

                    <Row gutter={[8, 8]}>
                        <Col span={24} style={{ margin: "2em", }}>
                            <TextField onChange={(val) => { handleChange("email", val.target.value) }} fullWidth={true} id="outlined-basic" label="Email" variant="outlined" color='secondary' />
                        </Col>
                        <Col span={24} style={{ margin: "2em" }}>
                            <TextField onChange={(val) => { handleChange("password", val.target.value) }} fullWidth={true} id="outlined-basic" label="Password" variant="outlined" type="password" color='secondary' />
                        </Col>
                        <Col span={24} style={{ margin: "2em", justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <Button style={{
                                backgroundColor: primaryColor, color: "#fff", fontWeight: "bold", padding: "0.5em 3em", fontSize: "1.2em", alignSelf: 'center'
                            }} variant="contained" onClick={handleSubmit}>
                                Login
                            </Button>
                        </Col>
                    </Row>
                    {/* </Col> */}
                </Row>
                <Row style={{ flex: 1, justifyContent: 'center' }}>
                    <img src={LoginImg}></img>
                </Row>
                <Row style={{ display: "flex", flexDirection: "row", position: "absolute", top: 0, right: "-15em", left: "auto", zIndex: -1 }}>
                    <Grid style={{ background: primaryColor + "88", height: "100vh", width: "50em", textAlign: "end", borderTopLeftRadius: "50%", borderBottomLeftRadius: "50%" }}>
                        <Grid style={{ background: primaryColor + "bb", height: "100vh", width: "40em", float: "right", borderTopLeftRadius: "50%", borderBottomLeftRadius: "50%" }}>
                            <Grid style={{ background: primaryColor, height: "100vh", width: "30em", float: "right", borderTopLeftRadius: "100%", borderBottomLeftRadius: "100%" }}>
                            </Grid>
                        </Grid>
                    </Grid>
                </Row>
            </Grid>
        </>
    );
};
export default Login;