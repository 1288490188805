import { Button, Popconfirm } from "antd";
import { makeAutoObservable } from "mobx";
import moment from "moment";
import helper from 't18helper/dist/fetchHelper';

class PortfolioTab {
    "name" = "portfolio"
    "collection" = false
    "displayConfig" = {
        "view": false,
        "edit": true,
        "createNew": true,
        "showTable": true,
        "bulkCreate": true,
        "filters": true
    }
    "columns" = [
        {
            "title": "Item Id",
            "dataIndex": "_id",
            "key": "_id",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
        },
        {
            "title": "Chef-Name",
            "dataIndex": "chefId",
            "key": "chefId",
            "showInTable": false,
            "type": "select",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            disableInView: true,
            "optional": true,
            "arr": [],

            onSearchFilter: async (val) => {
                if (val == '') return;
                console.log(val, 'onSearchFilter');
                var res = await helper.filterData("chefs", {
                    name: val,
                    limit: 10,
                });
                var names = res.res.map(item => {
                    return item['name'];
                })
                var values = res.res.map(item => {
                    return item['_id'];
                })
                console.log(names)
                this.columns[1].arr = names
                this.columns[1].values = values
            },
        },

        {
            "title": "Chef Name",
            "dataIndex": "chefName",
            "key": "chefName",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,

        },

        {
            "title": "Status",
            "dataIndex": "status",
            "key": "status",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
        },



        {
            "title": "Name",
            "dataIndex": "name",
            "key": "name",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
        },
        {
            "title": "Description",
            "dataIndex": "description",
            "key": "description",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
        },

        {
            "title": "Price",
            "dataIndex": "price",
            "key": "price",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            render: function (text, record) {
                text = text.toFixed(2)
                console.log(text, 'con')
                return '£ ' + text
            }
        },



        {
            "title": "Created On",
            "dataIndex": "cOn",
            "key": "cOn",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            render: function (text, record) {
                console.log(text, 'con')
                return moment(text).format('MMM Do YYYY');
            }
        },
        {
            "title": "Image",
            "dataIndex": "imgUrl",
            "key": "imgUrl",
            "showInTable": false,
            "type": "image",
            "filter": false,
            "secure": false,
            "optional": true,


            // "render": (val, record) => {

            //     return <Image src={val.url} />
            // }


        },

        {
            "title": "Action",
            "dataIndex": "Action",
            "key": "deliverOn",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "render": (val, record) => {

                return (
                    <>
                        {record.status != "APPROVED" && <Popconfirm
                            title="Are you sure to approve?"
                            onConfirm={async () => {
                                console.log("clicked", record._id);
                                let res = await helper.updateData({ _id: record._id, "status": "APPROVED" }, "portfolio")
                                console.log(res)
                                if (res.status === 200) {
                                    window.location.reload();

                                    // helper.message.success('approved successfully')
                                    // await this.getData()
                                    return res
                                }
                                return helper.message.error('error approving data')

                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        ><Button type="primary" size="small" style={{ borderColor: "green", color: 'green' }} ghost
                        >Approve</Button></Popconfirm>}

                        {record.status != "PENDING" && <Popconfirm
                            title="Are you sure to disable?"
                            onConfirm={async () => {
                                console.log("clicked", record._id);
                                let res = await helper.updateData({ _id: record._id, "status": "PENDING" }, "portfolio")
                                console.log(res)
                                if (res.status === 200) {
                                    window.location.reload();

                                    // helper.message.success('approved successfully')
                                    // await this.getData()
                                    return res
                                }
                                return helper.message.error('error approving data')

                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        ><Button type="primary" size="small" style={{ borderColor: "red", color: 'red' }} ghost
                        >Disable</Button></Popconfirm>}
                    </>

                );
            }
        }




    ]

}
var obj = new PortfolioTab()
makeAutoObservable(obj)
export { obj }