import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import Base from "../screens/BaseScreen";
import ChefPayments from "../screens/ChefPayments";
import Chefs from "../screens/Chefs";
import ContactUs from "../screens/ContactUs";
import ContactUsTab from "../screens/ContactUsTab";
import Orders from "../screens/Orders";
import Portfolio from "../screens/Portfolio";
import Users from "../screens/Users";
import Wishes from "../screens/Wishes";
const RouteSwitcher = (props) => {
    let { path, url } = useRouteMatch();

    return (
        <Switch>

            <Route path="/chefs" exact> <Chefs /></Route>
            <Route path="/users" exact> <Users /></Route>
            <Route path="/orders" exact> <Orders /></Route>
            <Route path="/wishes" exact> <Wishes /></Route>
            <Route path="/portfolio" exact> <Portfolio /></Route>
            <Route path="/chefpayments" exact> <ChefPayments /></Route>
            <Route path="/contactus" exact> <ContactUs /></Route>

        </Switch>
    );
};

export default RouteSwitcher;
