import { useEffect } from 'react'
import { observer } from 'mobx-react';
import { dbstore } from '../../dataLayer/dbStore';
import CRUDComp from '../CRUDComp';
import { obj } from "../../configs/ChefTab";
import T18Crud from '../common/t18Crud/T18Crud';

// var columns = obj.columns;
// var displayConfig = obj.displayConfig;

const ChefTab = observer(({ props }) => {
    useEffect(() => {
        // dbstore.filterData("chef", { status: "APPROVED" });
    }, [])
    return (
        <>
            <p>
                {obj.columns[5].type}
            </p>
            <T18Crud obj={obj} />
        </>
    );
});
export default ChefTab;