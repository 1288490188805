import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  useLocation, NavLink
} from "react-router-dom";
import { Row, Col, Image } from 'antd'
import T18NavLink from "./NavLink";

import logo from '../../assets/Logo.png'

const SideNav = (props) => {

  return (
    <Row style={{ backgroundColor: '#fff', width: '100%', height: '100vh' }} className="t18_sidenav">
      <Col span={24} >
        {/* <Image src={require('../../assets/Logo.png')} /> */}
        <Row style={{ justifyContent: 'center', alignItems: 'center', padding: 30, backgroundColor: '#fff0f0', marginBottom: 50 }}>
          <img src={logo} alt="logo" width="70%" />

        </Row>

        <T18NavLink to="/chefs" title={"Chefs"}></T18NavLink>
        <T18NavLink to="/users" title={"Users"}></T18NavLink>
        <T18NavLink to="/orders" title={"Orders"}></T18NavLink>
        <T18NavLink to="/wishes" title={"Wishes"}></T18NavLink>
        <T18NavLink to="/portfolio" title={"Portfolio"}></T18NavLink>
        <T18NavLink to="/chefpayments" title={"Chef Payments"}></T18NavLink>
        <T18NavLink to="/contactus" title={"Contact Us"}></T18NavLink>

      </Col>
    </Row>
  );
};

export default SideNav;
