import { Button, Popconfirm, Modal, Dropdown, Space, Input } from "antd";
import { makeAutoObservable } from "mobx";
import moment from "moment";
import React, { useState } from "react";
import helper from 't18helper/dist/fetchHelper';
import { DownOutlined } from '@ant-design/icons';


const CancelInfopopup = ({ record }) => {
    // alert('hello')
    const [active, seActive] = useState(false)
    const [dropdownval, setDropDownVal] = useState(record.cancelledBy ? record.cancelledBy : 'Canceled By')
    const [saved, setSaved] = useState(null)

    var saveButtonStat = false
    const setSaveButttonStat = () => {
        if (record.chefRefund.status == 'paid' && record.userRefund.status == 'paid') {
            saveButtonStat = true
        }
    }
    setSaveButttonStat()

    const items = [
        {
            label: <div onClick={() => { setDropDownVal('admin'); }} style={{ color: 'black', width: '250px', fontSize: 20, fontWeight: 'bold' }}>Admin</div>,
            key: '0',
        },
        {
            label: <div onClick={() => { setDropDownVal('user'); }} style={{ color: 'black', width: '250px', fontSize: 20, fontWeight: 'bold' }}>User</div>,
            key: '1',
        },
        {
            label: <div onClick={() => { setDropDownVal('chef'); }} style={{ color: 'black', width: '250px', fontSize: 20, fontWeight: 'bold' }}>Chef</div>,
            key: '3',
        },
    ];
    const data = {
        "canceledBy": dropdownval,
        "chefRefund": record.chefRefund ? record.chefRefund.amount : null,
        "UserRefund": record.userRefund ? record.userRefund.amount : null

    }


    return (
        <>
            <Button onClick={() => { seActive(true); }} type="primary" size="small" style={{ borderColor: 'orange', color: 'orange' }} ghost
            >Cancel Info</Button>
            <Modal
                title="Cancel Info"
                centered
                open={active}
                onOk={async () => {

                    console.log(record)
                    // alert(record._id)
                    // alert(JSON.stringify(data))
                    if (data.canceledBy == 'admin' || data.canceledBy == 'user' || data.canceledBy == 'chef') {
                        console.log("")
                    }
                    else {
                        alert('Select canceled by from dropdown')
                        return;
                    }

                    if (record.chefRefund.amount == false || record.userRefund.amount == false) {
                        if (data.UserRefund.length == 0 || data.chefRefund.length == 0) {
                            alert('Enter Valid Refund Data')
                            return;
                        }
                    }
                    // else if(record.chefRefund.amount==true || record.userRefund.amount==true){
                    //     data.UserRefund = record.userRefund.amount
                    //     data.chefRefund = record.chefRefund.amount
                    // }


                    let res = await helper.updateData({ _id: record._id, "cancelledBy": data.canceledBy, chefRefund: { amount: data.chefRefund }, userRefund: { amount: data.UserRefund } }, "orders")
                    console.log(res)
                    if (res.status === 200) {
                        window.location.reload();

                        // helper.message.success('approved successfully')
                        // await this.getData()
                        return res
                    }
                    return helper.message.error('error approving data')

                    seActive(false);
                }}
                onCancel={() => seActive(false)}
                width={500}
                okText="save"
                okButtonProps={{
                    disabled: saveButtonStat
                }}
            // style={{display:'flex', flexDirection:'column', alignItems:'center'}}
            >
                <div style={{ width: '57%', height: 35, marginLeft: '100px', borderWidth: '0.5px', borderStyle: 'solid', borderColor: '#cccaca', marginBottom: '20px', borderRadius: 5, }}>
                    <Dropdown overlayStyle={{}} menu={{ items }} trigger={['click']}>
                        <a onClick={(e) => e.preventDefault()}>
                            <Space>
                                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                    <div style={{ fontSize: 18, color: 'black', fontWeight: 'bold', paddingLeft: '10px', opacity: dropdownval == 'Canceled By' ? 0.5 : null, width: '240px' }}>
                                        {dropdownval}

                                    </div>
                                    <div>
                                        <DownOutlined style={{ marginLeft: 0, fontWeight: 'bold', color: 'black' }} size={20} />
                                    </div>

                                </div>


                            </Space>
                        </a>
                    </Dropdown>
                </div>
                <div style={{ width: '100%', display: 'flex', alignItems: 'center', flexDirection: 'column', paddingLeft: '100px', paddingRight: '100px' }}>
                    <div style={{ color: 'grey', fontSize: 15, marginTop: 0, marginBottom: 5, width: '100%' }}>
                        Chef Refund
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Input placeholder={"Chef Refund"} size="large" onChange={(val) => { console.log(val.target.value); data.chefRefund = val.target.value }} defaultValue={record.chefRefund ? String(record.chefRefund.amount) : null} />
                        {
                            record.chefRefund.status == 'paid' ?
                                <div
                                    style={{ width: '60px', height: '30px', borderRadius: '5px', backgroundColor: 'red', alignItems: 'center', justifyContent: 'center', marginLeft: 10, display: 'flex', opacity: 0.5 }}>
                                    <div style={{ color: 'white', fontSize: 14, fontWeight: '500' }}>
                                        Paid
                                    </div>
                                </div>
                                :
                                <button
                                    disabled={record.cancelledBy == "Canceled By" ? true : false}
                                    onClick={async () => {
                                        // if(record.chefRefund.amount==true || record.userRefund.amount==true){
                                        //     data.UserRefund = record.userRefund.amount
                                        //     data.chefRefund = record.chefRefund.amount
                                        // }
                                        if (record.chefRefund.status == true) {
                                            if (record.chefRefund.status == 'paid') {
                                                return;
                                            }
                                        }
                                        let res = await helper.updateData({ _id: record._id, "cancelledBy": data.canceledBy, chefRefund: { amount: data.chefRefund, status: 'paid' }, userRefund: { amount: data.UserRefund, status: record.userRefund.status ? record.userRefund.status : '' } }, "orders")
                                        console.log(res)
                                        if (res.status === 200) {
                                            window.location.reload();
                                            return res
                                        }
                                        return helper.message.error('error approving data')
                                    }}
                                    style={{ width: '60px', height: '30px', borderRadius: '5px', backgroundColor: 'red', alignItems: 'center', justifyContent: 'center', marginLeft: 10, display: 'flex', cursor: 'pointer', borderWidth: 0, opacity: record.cancelledBy == "Canceled By" ? 0.5 : 1 }}>
                                    <div style={{ color: 'white', fontSize: 14, fontWeight: '500' }}>
                                        Paid
                                    </div>
                                </button>
                        }

                    </div>
                    <div style={{ height: '20px' }}></div>
                    <div style={{ color: 'grey', fontSize: 15, marginTop: 0, marginBottom: 5, width: '100%' }}>
                        User Refund
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Input placeholder="User Refund" size="large" onChange={(val) => { console.log(val.target.value); data.UserRefund = val.target.value }} defaultValue={record.userRefund ? String(record.userRefund.amount) : null} />
                        {
                            record.userRefund.status == 'paid' ?
                                <div
                                    style={{ width: '60px', height: '30px', borderRadius: '5px', backgroundColor: 'red', alignItems: 'center', justifyContent: 'center', marginLeft: 10, display: 'flex', opacity: 0.5 }}>
                                    <div style={{ color: 'white', fontSize: 14, fontWeight: '500' }}>
                                        Paid
                                    </div>
                                </div>
                                :
                                <button
                                    disabled={record.cancelledBy == "Canceled By" ? true : false}
                                    onClick={async () => {
                                        // if(record.chefRefund.amount==true || record.userRefund.amount==true){
                                        //     data.UserRefund = record.userRefund.amount
                                        //     data.chefRefund = record.chefRefund.amount
                                        // }
                                        if (record.userRefund.status == true) {
                                            if (record.userRefund.status == 'paid') {
                                                return;
                                            }
                                        }
                                        let res = await helper.updateData({ _id: record._id, "cancelledBy": data.canceledBy, chefRefund: { amount: data.chefRefund, status: record.chefRefund.status ? record.chefRefund.status : '' }, userRefund: { amount: data.UserRefund, status: 'paid' } }, "orders")
                                        console.log(res)
                                        if (res.status === 200) {
                                            window.location.reload();
                                            return res
                                        }
                                        return helper.message.error('error approving data')
                                    }}
                                    style={{ width: '60px', height: '30px', borderRadius: '5px', backgroundColor: 'red', alignItems: 'center', justifyContent: 'center', marginLeft: 10, display: 'flex', cursor: 'pointer', opacity: record.cancelledBy == "Canceled By" ? 0.5 : 1, borderWidth: 0 }}>
                                    <div style={{ color: 'white', fontSize: 14, fontWeight: '500' }}>
                                        Paid
                                    </div>
                                </button>
                        }
                    </div>
                    {/* <Input placeholder="User Refund" size="large" onChange={(val)=>{console.log(val.target.value); data.UserRefund=val.target.value}}/> */}

                </div>

            </Modal>
        </>
    )

}

class OrderTab {
    "name" = "orders"
    "collection" = false
    "displayConfig" = {
        "view": false,
        "edit": true,
        "createNew": true,
        "showTable": true,
        "bulkCreate": true,
        "filters": true
    }
    "columns" = [
        {
            "title": "Order Id",
            "dataIndex": "_id",
            "key": "_id",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
        },
        {
            "title": "Chef-Name",
            "dataIndex": "chefId",
            "key": "chefId",
            "showInTable": false,
            "type": "select",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            disableInView: true,
            "optional": true,
            "arr": [],

            onSearchFilter: async (val) => {
                if (val == '') return;
                console.log(val, 'onSearchFilter');
                var res = await helper.filterData("chefs", {
                    name: val,
                    limit: 100,
                });
                var names = res.res.map(item => {
                    return item['name'];
                })
                var values = res.res.map(item => {
                    return item['_id'];
                })
                console.log(names)
                this.columns[1].arr = names
                this.columns[1].values = values
            },
        },

        {
            "title": "User-Name",
            "dataIndex": "userId",
            "key": "userId",
            "showInTable": false,
            "type": "select",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            disableInView: true,
            "optional": true,
            "arr": [],

            onSearchFilter: async (val) => {
                if (val == '') return;
                console.log(val, 'onSearchFilter');
                var res = await helper.filterData("users", {
                    name: val,
                    limit: 100,
                });
                var names = res.res.map(item => {
                    return item['name'];
                })
                var values = res.res.map(item => {
                    return item['_id'];
                })
                console.log(names)
                this.columns[2].arr = names
                this.columns[2].values = values
            },
        },


        {
            "title": "Status",
            "dataIndex": "status",
            "key": "status",
            "showInTable": true,
            "type": "select",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            arr: ["Created", "Cooking", "Cooked", "Transit", "Delivered", "Cancelled"],
            values: ["created", "cooking", "cooked", "transit", "delivered", "cancelled"],
            // render: function (text, record)  {
            //     if (record.status=="cancelled") {
            //         if (record.userRefund.status=="paid" && record.chefRefund.status=="paid") {
            //             return 'refunded'
            //         }
            //         else{
            //             return record.status
            //         }
            //     }
            //     else{
            //         return record.status
            //     }
            // }
        },

        {
            "title": "Payment Status",
            "dataIndex": "paymentStatus",
            "key": "paymentStatus",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
        },

        {
            "title": "Order Total",
            "dataIndex": "totalPrice",
            "key": "totalPrice",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            render: function (text, record) {
                text = text.toFixed(2)
                return '£ ' + text;
            }
        },
        {
            "title": "Deliver On",
            "dataIndex": "deliverOn",
            "key": "deliverOn",
            "showInTable": true,
            "type": "date",
            "filter": true,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            // "disableInView": true,

            render: function (text, record) {
                return moment(text).format('MMM Do YYYY');
            }
        },

        {
            "title": "Review",
            "dataIndex": "review",
            "key": "review",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
        },
        {
            "title": "Review On",
            "dataIndex": "reviewOn",
            "key": "reviewOn",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            render: function (text, record) {
                return moment(text).format('MMM Do YYYY');
            }
        },


        {
            "title": "Rating",
            "dataIndex": "rating",
            "key": "rating",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,

        },
        {
            "title": "Chef Name",
            "dataIndex": "chefInfo",
            "key": "chefInfo",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "optional": true,
            "disableInView": true,

            fieldsType: {},
            render: function (text, record) {
                return text?.name
            }


        },
        {
            "title": "User Name",
            "dataIndex": "userInfo",
            "key": "userInfo",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "optional": true,
            "disableInView": true,

            fieldsType: {},
            render: function (text, record) {
                return text?.name
            }


        },

        {
            "title": "Order Actions",
            "dataIndex": "temp",
            "key": "temp",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "render": (val, record) => {

                return (
                    <>
                        {record.status != "cancelled" && record.status != "completed" && <Popconfirm
                            title="Are you sure want to cancel?"
                            onConfirm={async () => {
                                console.log("clicked", record._id);
                                let res = await helper.updateData({ _id: record._id, "status": "cancelled" }, "orders")
                                let res1 = await helper.updateData({ _id: record._id, "cancelledBy": 'Canceled By', chefRefund: { amount: 0, status: 'pending' }, userRefund: { amount: 0, status: 'pending' } }, "orders")
                                console.log(res1)
                                console.log(res)
                                if (res.status === 200) {
                                    window.location.reload();

                                    // helper.message.success('approved successfully')
                                    // await this.getData()
                                    return res
                                }
                                if (res1.status === 200) {
                                    window.location.reload();
                                    return res1
                                }
                                return helper.message.error('error approving data')
                                // console.log(res1)
                                // if (res1.status === 200) {
                                //     window.location.reload();
                                //     return res1
                                // }
                                // return helper.message.error('error approving data')

                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        ><Button type="primary" size="small" style={{ borderColor: "green", color: 'green' }} ghost
                        >Cancel</Button></Popconfirm>}
                    </>

                );
            }
        },
        {
            "title": "Cancel Info",
            "dataIndex": "temp",
            "key": "temp",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "render": (val, record) => {

                return (
                    <>
                        {
                            record.status == 'cancelled' ?
                                <CancelInfopopup
                                    record={record}
                                />
                                :
                                <></>
                        }
                    </>
                )

            }
        },
        {
            "title": "Review Actions",
            "dataIndex": "temp1",
            "key": "temp1",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
            "render": (val, record) => {

                return (
                    <>
                        {record.reviewStatus == "pending" && <><Popconfirm
                            title="Are you sure want to approve?"
                            onConfirm={async () => {
                                console.log("clicked", record._id);
                                let res = await helper.updateData({ _id: record._id, "reviewStatus": "approved" }, "orders")
                                console.log(res)
                                if (res.status === 200) {
                                    window.location.reload();

                                    // helper.message.success('approved successfully')
                                    // await this.getData()
                                    return res
                                }
                                return helper.message.error('error approving data')

                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        ><Button type="primary" size="small" style={{ borderColor: "green", color: 'green' }} ghost
                        >Approve</Button></Popconfirm><span>   </span>

                            <Popconfirm

                                title="Are you sure want to reject?"
                                onConfirm={async () => {
                                    console.log("clicked", record._id);
                                    let res = await helper.updateData({ _id: record._id, "reviewStatus": "rejected" }, "orders")
                                    console.log(res)
                                    if (res.status === 200) {
                                        window.location.reload();

                                        // helper.message.success('approved successfully')
                                        // await this.getData()
                                        return res
                                    }
                                    return helper.message.error('error approving data')

                                }}
                                // onCancel={cancel}
                                okText="Yes"
                                cancelText="No"
                            ><Button type="primary" size="small" style={{ borderColor: "red", color: 'red' }} ghost
                            >Reject</Button></Popconfirm>


                        </>}
                    </>

                );
            }
        },
        {
            "title": "Chef Info",
            "dataIndex": "chefInfo",
            "key": "chefInfo",
            "showInTable": false,
            "type": "object",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                _id: true,
                cuisineTypes: true,
                age: true,
                gender: true,
                description: true

            },
            fieldsType: {}


        },


        {
            "title": "User Info",
            "dataIndex": "userInfo",
            "key": "userInfo",
            "showInTable": false,
            "type": "object",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                _id: true,
                cuisineTypes: true,
                age: true,
                gender: true,
                description: true

            },
            fieldsType: {}


        },
        {
            "title": "Delivery Address",
            "dataIndex": "deliveryAddress",
            "key": "deliveryAddress",
            "showInTable": false,
            "type": "object",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                index: true,
                latitude: true,
                longitude: true,
                location: true,
                locality: true,
                county: true,
            },
            fieldsType: {}


        },

        {
            "title": "Items",
            "dataIndex": "items",
            "key": "items",
            "showInTable": false,
            "type": "array",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                _id: true,
                chefId: true,
                cuisine: true,
                description: true,
                isAvailableNext7Days: true,
                isEnabled: true,
                isAvailableToday: true,
                soldCount: true,
                status: true,
                id: true,
                menuId: true,
                cumulatedPrice: true,
                imgUrl: true,
            },
            fieldsType: {}


        },
        {
            "title": "User Refund",
            "dataIndex": "userRefund",
            "key": "userRefund",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "optional": true,
            // disableFields: {
            //     amount:false
            // },
            // fieldsType: {}
            render: function (val, record) {
                return record.userRefund.amount || "-"
            }
        },
        {
            "title": "User Refund Status",
            "dataIndex": "userRefund",
            "key": "userRefund",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "optional": true,
            // disableFields: {
            //     amount:false
            // },
            // fieldsType: {}
            render: function (val, record) {
                if (record.userRefund.status == "paid" && record.userRefund.amount >= 0) {
                    return 'paid'
                }
                else if (record.userRefund.status != "pending" && record.userRefund.amount >= true) {
                    return 'saved'
                }
                else {
                    return 'not updated'
                }
            }
        },
        {
            "title": "Chef Refund",
            "dataIndex": "chefRefund",
            "key": "chefRefund",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "optional": true,
            // disableFields: {
            //     amount:false
            // },
            // fieldsType: {}
            render: function (val, record) {
                return record.chefRefund.amount || "-"
            }
        },
        {
            "title": "Chef Refund status",
            "dataIndex": "chefRefund",
            "key": "chefRefund",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "optional": true,
            // disableFields: {
            //     amount:false
            // },
            // fieldsType: {}
            render: function (val, record) {
                if (record.chefRefund.status == "paid" && record.chefRefund.amount >= 0) {
                    return 'paid'
                }
                else if (record.chefRefund.status != "pending" && record.chefRefund.amount >= true) {
                    return 'saved'
                }
                else {
                    return 'not updated'
                }
            }
        },

    ]

}
var obj = new OrderTab()
makeAutoObservable(obj)
export { obj }