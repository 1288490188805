import { Button, Popconfirm } from "antd";
import { makeAutoObservable } from "mobx";
import helper from 't18helper/dist/fetchHelper';

class ChefPaymentTab {
    "name" = "contactus"

    "collection" = false
    temp = ',lll'
    "displayConfig" = {
        "view": true,
        "edit": false,
        "createNew": false,
        "showTable": true,
        "bulkCreate": false,
        "filters": true
    }
    "columns" = [

        {
            "title": "Name",
            "dataIndex": "name",
            "key": "name",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": false,
            "optional": true,

        },
        {
            "title": "Phone",
            "dataIndex": "phone",
            "key": "phone",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": false,
            "optional": true,

        },
        {
            "title": "Email",
            "dataIndex": "email",
            "key": "email",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": false,
            "optional": true,

        },
        {
            "title": "Message",
            "dataIndex": "message",
            "key": "message",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": false,
            "optional": true,

        },

    ]

}
const obj = new ChefPaymentTab()
makeAutoObservable(obj)
export { obj }