import { InputNumber, Input, Switch, Upload, Select, Image, Row, Col, DatePicker } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { dbstore } from '../../dataLayer/dbStore';
import { useState } from 'react'
import moment from 'moment';

const { Dragger } = Upload;
const { Option } = Select;

const FormRenderer = ({ data, onChange, value, disabled = [], edit = false, view = false, disable = false }) => {
    const [uploadUrl, setuploadUrl] = useState(null)

    var Comp;
    switch (data.type) {
        case "number":
            Comp = <><p style={{ fontWeight: 'bold', marginBottom: 5 }}>{data.title}:</p><InputNumber defaultValue={0} onChange={onChange} value={value} disabled={view || disable} /></>
            break;
        case "bool":
            Comp = <><p style={{ fontWeight: 'bold', marginBottom: 5 }}>{data.title}:</p><Switch defaultChecked={false} checked={value} onChange={onChange} disabled={view} /></>
            break;
        case "image":
            Comp = <div style={{ marginBottom: 20, width: '100%' }}><p style={{ fontWeight: 'bold', marginBottom: 5 }}>{data.title}:</p>
                <Row>
                    {edit && value &&
                        <Col span={12}>
                            <Image
                                width={200}
                                src={value.url}

                            />


                        </Col>}
                    <Col span={12}>
                        <Dragger

                            method={"PUT"}
                            customRequest={async (file) => {
                                console.log('custom req ', file);
                                onChange(file.file);
                                file.onSuccess()



                            }}
                        >
                            <p className="ant-upload-drag-icon">
                                <InboxOutlined />
                            </p>
                            <p className="ant-upload-text">Click or drag file to this area to upload</p>
                            <p className="ant-upload-hint">
                                Support for a single or bulk upload. Strictly prohibit from uploading company data or other
                                band files
                            </p>
                        </Dragger>
                    </Col>

                </Row>

            </div>
            break
        case "enum":
        case "select":
            Comp = <><p style={{ fontWeight: 'bold', marginBottom: 5 }}>{data.title}:</p>
                <Select placeholder={data.title} value={value} style={{ width: '100%' }} showSearch onChange={onChange}
                    allowClear disabled={view}>

                    {data.arr.map((item, index) => {
                        return <Option value={data.values ? data.values[index] : item} disabled={disabled.indexOf(data.values ? data.values[index] : item) != -1}>{item.toUpperCase()}</Option>

                    })}
                </Select>

            </>
            break

        case "multiSelect":
            Comp = <><p style={{ fontWeight: 'bold', marginBottom: 5 }}>{data.title}:</p>
                <Select placeholder={data.title} style={{ width: '100%' }} showSearch mode="multiple" onChange={onChange}
                    allowClear value={value} disabled={view}>

                    {data.arr.map((item, index) => {
                        return <Option value={data.values ? data.values[index] : item}>{item.toUpperCase()}</Option>

                    })}
                </Select>

            </>
            break
        case "date":
            Comp = <><p style={{ fontWeight: 'bold', marginBottom: 5 }}>{data.title}:</p><DatePicker onChange={(date) => { onChange(moment(date).format('x')) }} />
            </>
            break
        default:
            Comp = <><p style={{ fontWeight: 'bold', marginBottom: 5 }}>{data.title}:</p><Input placeholder={data.title} size="large" onChange={(e) => { onChange(e.target.value) }} value={value} disabled={view} /></>
            break;


    }
    return Comp
}

export { FormRenderer };