import { InputNumber, Input, Switch, Upload, Select, Image, Row, Col, DatePicker } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { dbstore } from '../../dataLayer/dbStore';
import { useState } from 'react'
import moment from 'moment';
import { observer } from 'mobx-react';

const { Dragger } = Upload;
const { Option } = Select;

const FilterRenderer = observer(({ data, onChange, value, disabled = [], edit = false }) => {
    const [uploadUrl, setuploadUrl] = useState(null)

    var Comp;
    switch (data.type) {
        case "number":
            Comp = <><p style={{ fontWeight: 'bold', marginBottom: 5 }}>{data.title}:</p><InputNumber defaultValue={0} onChange={onChange} value={value} /></>
            break;
        case "bool":
            Comp = <><p style={{ fontWeight: 'bold', marginBottom: 5 }}>{data.title}:</p><Switch defaultChecked={false} checked={value} onChange={onChange} /></>
            break;

        case "enum":
        case "select":
        case "multiSelect":
            Comp = <><p style={{ fontWeight: 'bold', marginBottom: 5 }}>{data.title}:</p>
                <Select placeholder={data.title} value={value} style={{ width: '100%' }} showSearch onChange={onChange}
                    allowClear
                    filterOption={(inputValue, option) => { return true }}

                    onSearch={(value) => {
                        // console.log(value);
                        if (data.onSearchFilter) {
                            data.onSearchFilter(value)
                        }
                    }}
                >

                    {data.arr.map((item, index) => {
                        return <Option value={data.values ? data.values[index] : item} disabled={disabled.indexOf(data.values ? data.values[index] : item) != -1}>{item.toUpperCase()}</Option>

                    })}
                </Select>

            </>
            break
        case "date":
            Comp = <><p style={{ fontWeight: 'bold', marginBottom: 5 }}>{data.title}:</p><DatePicker onChange={(date) => { onChange(moment(date).format('YYYY-MM-DD')) }} />
            </>
            break

        default:
            Comp = <><p style={{ fontWeight: 'bold', marginBottom: 5 }}>{data.title}:</p><Input placeholder={data.title} size="large" onChange={(e) => { onChange(e.target.value) }} value={value} /></>
            break;


    }
    return Comp
})

export { FilterRenderer };