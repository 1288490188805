import { Button, Popconfirm, Image, DatePicker } from "antd";
import { makeAutoObservable } from "mobx";
import moment from "moment";
import helper from 't18helper/dist/fetchHelper';
const statusFilter = ['all', 'active', 'inactive'];
class ChefTab {
    "name" = "chefs"
    "collection" = false
    temp = ',lll'
    "displayConfig" = {
        "view": true,
        "edit": false,
        "createNew": false,
        "showTable": true,
        "bulkCreate": false,
        "filters": true
    }
    "columns" = [
        {
            "title": "Chef Id",
            "dataIndex": "_id",
            "key": "_id",
            "showInTable": false,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
        },
        {

            "title": "Name",
            "dataIndex": "name",
            "key": "name",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": false,
            "optional": true,
        },
        {
            "title": "Email Id",
            "dataIndex": "email",
            "key": "email",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": false,
            "optional": true,
        },
        {
            "title": "Phone",
            "dataIndex": "phone",
            "key": "phone",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": false,
            "optional": true,
        },
        {
            "title": "Gender",
            "dataIndex": "gender",
            "key": "gender",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": false,
            "optional": true,
        },
        {
            "title": "Rating",
            "dataIndex": "rating",
            "key": "rating",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": false,
            "optional": true,
        },
        {
            "title": "status",
            "dataIndex": "status",
            "key": "status",
            "showInTable": true,
            "type": "enum",
            "filter": true,
            "secure": false,
            "optional": true,
            "disableInForm": false,


            "arr": ['PENDING', 'APPROVED', 'DISABLED'],
            'colors': { PENDING: 'blue', APPROVED: 'green', REJECTED: 'red', DISABLED: 'yellow' },
            // "render": (val, record) => {

            //     return <p>{JSON.stringify(val)}</p>
            // }

        },

        {
            "title": "Address",
            "dataIndex": "address",
            "key": "address",
            "showInTable": false,
            "type": "object",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                index: true,
                latitude: true,
                longitude: true,
                location: true,
                locality: true,
                county: true,
            },
            fieldsType: {}


        },

        {
            "title": "Profile Pic",
            "dataIndex": "profilePic",
            "key": "profilePic",
            "showInTable": false,
            "type": "object",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                secure: true,
            },
            fieldsType: {

                url: 'image'

            },

            // "render": (val, record) => {

            //     return <Image src={val.url} />
            // }


        },
        {
            "title": "Id Proof",
            "dataIndex": "idProof",
            "key": "idProof",
            "showInTable": false,
            "type": "object",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                secure: true,
            },
            fieldsType: {

                url: 'pdf'

            }


        },
        {
            "title": "Address Proof",
            "dataIndex": "addressProof",
            "key": "addressProof",
            "showInTable": false,
            "type": "object",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                secure: true,
            },
            fieldsType: {

                url: 'pdf'

            }


        },
        {
            "title": "Food Certificate",
            "dataIndex": "foodCertificate",
            "key": "foodCertificate",
            "showInTable": false,
            "type": "object",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                secure: true,
            },
            fieldsType: {

                url: 'pdf'

            }


        },

        {
            "title": "Action",
            "dataIndex": "action",
            "key": "action",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            disableInView: true,
            "optional": true,
            "render": (val, record) => {

                return (
                    <>
                        {(record.status == "PENDING" || record.status == "DISABLED") && <Popconfirm
                            title="Are you sure to approve?"
                            onConfirm={async () => {
                                console.log("clicked", record._id);
                                let res = await helper.updateData({ _id: record._id, "status": "APPROVED" }, "chefs")
                                console.log(res)
                                if (res.status === 200) {
                                    window.location.reload();

                                    // helper.message.success('approved successfully')
                                    // await this.getData()
                                    return res
                                }
                                return helper.message.error('error approving data')

                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        ><Button type="primary" size="small" style={{ borderColor: "green", color: 'green' }} ghost
                        >Approve</Button></Popconfirm>}

                        {record.status == "APPROVED" && <Popconfirm
                            title="Are you sure to disable?"
                            onConfirm={async () => {
                                console.log("clicked", record._id);
                                let res = await helper.updateData({ _id: record._id, "status": "DISABLED" }, "chefs")
                                console.log(res)
                                if (res.status === 200) {
                                    window.location.reload();

                                    // helper.message.success('approved successfully')
                                    // await this.getData()
                                    return res
                                }
                                return helper.message.error('error disabling chef')

                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        ><Button type="primary" size="small" style={{ borderColor: "red", color: 'red' }} ghost
                        >Disable</Button></Popconfirm>}
                    </>

                );
            }
        },

        {
            "title": "Expiry",
            "dataIndex": "expiry",
            "key": "expiry",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            disableInView: true,
            "optional": true,
            "render": (val, record) => {

                return (
                    <>
                        <p>{val ? moment(val).format('DD MMM,YYYY') : 'no expiry set'}</p>
                        <DatePicker placeholder={'Update expiry'} onChange={(val) => {
                            console.log(val)
                            if (moment().isAfter(val)) {
                                helper.message.error('expiry date cannot be in the past')
                                return
                            }
                            helper.updateData({ _id: record._id, expiry: moment(val).toISOString() }, "chefs")
                        }} />
                    </>

                );
            }
        },


        {
            "title": "Bank Details",
            "dataIndex": "bankDetails",
            "key": "bankDetails",
            "showInTable": false,
            "type": "object",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                _id: true,
                // drop: true,
                // deliverImage: true,
                orderId: true


            },
            fieldsType: {
                // deliverImage: {
                //     url: 'pdf'
                // },
            },
            render: {
                // drop: (val) => addressJoin(val)
            }


        },
    ]

}
const obj = new ChefTab()
makeAutoObservable(obj)
export { obj }