var urls = {
    getUrls: {
        "chefs": "/superadmin/chefs",
        "users": "/superadmin/users",
        "orders": "/superadmin/orders",
        "wishes": "/superadmin/wishes",
        "portfolio": "/superadmin/items",
        "chefpayments": "/superadmin/chef/payments",
        "contactus": "/superadmin/contactus"

    },
    postUrls: {
        token: '/admin/token',
        logout: '/admin/logout',
        "wishes": "/superadmin/wishes",
        "calculateChefpayments": "/superadmin/payment/recalculate"


    },
    putUrls: {
        "chefs": "/superadmin/chefs",
        "users": "/superadmin/users",

        "wishes": "/superadmin/wishes",
        "orders": "/superadmin/orders",
        "portfolio": "/superadmin/items",
        "chefpayments": "/superadmin/chef/payments"




    },
}

export default urls