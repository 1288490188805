import { Col, Collapse, Row } from "antd"
import { FilterRenderer } from "./FilterRenderer";
import { FormRenderer } from "./FormRenderer";
const { Panel } = Collapse;
var obj = {
    number: 6,
    bool: 6,
    image: 24,
    string: 12

}
const T18Form = ({ filters, onChange, columns, defaultFilterState }) => {



    return (
        <div>

            <Row gutter={[32, 24]} style={{ padding: 30 }} >
                {columns.map((item, index) => {

                    if (!item.disableInForm) {
                        return <Col span={obj[item.type] || 6}><FormRenderer data={item} /></Col>
                    }


                })}
            </Row>
        </div>
    )


}

export default T18Form;