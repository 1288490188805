import { useEffect } from 'react'
import { observer } from 'mobx-react';
import { dbstore } from '../../dataLayer/dbStore';
import CRUDComp from '../CRUDComp';
import { obj } from "../../configs/ChefPaymentTab";
import T18Crud from '../common/t18Crud/T18Crud';

var columns = obj.columns;
var displayConfig = obj.displayConfig;

const ChefPaymentsTab = observer(({ props }) => {
    useEffect(() => {
    }, [])
    return (

        <T18Crud obj={obj} />
    );
});
export default ChefPaymentsTab;