import { Button, Popconfirm } from "antd";
import { makeAutoObservable } from "mobx";
import moment from "moment";
import helper from 't18helper/dist/fetchHelper';
const statusFilter = ['all', 'active', 'inactive'];
class ChefPaymentTab {
    "name" = "chefpayments"
    "collection" = false
    temp = ',lll'
    "displayConfig" = {
        "view": true,
        "edit": false,
        "createNew": false,
        "showTable": true,
        "bulkCreate": false,
        "filters": true
    }
    "columns" = [
        {
            "title": "Payment Id",
            "dataIndex": "_id",
            "key": "_id",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,
        },
        // {

        //     "title": "Name",
        //     "dataIndex": "name",
        //     "key": "name",
        //     "showInTable": true,
        //     "type": "string",
        //     "filter": true,
        //     "secure": false,
        //     "disableInForm": false,
        //     "optional": true,
        // },
        // {
        //     "title": "Email Id",
        //     "dataIndex": "email",
        //     "key": "email",
        //     "showInTable": true,
        //     "type": "string",
        //     "filter": true,
        //     "secure": false,
        //     "disableInForm": false,
        //     "optional": true,
        // },
        // {
        //     "title": "Phone",
        //     "dataIndex": "phone",
        //     "key": "phone",
        //     "showInTable": true,
        //     "type": "string",
        //     "filter": true,
        //     "secure": false,
        //     "disableInForm": false,
        //     "optional": true,
        // },
        {
            "title": "Chef Name",
            "dataIndex": "chefName",
            "key": "chefName",
            "showInTable": true,
            "type": "string",
            "filter": true,
            "secure": false,
            "disableInForm": false,
            "optional": true,

        },
        {
            "title": "Total",
            "dataIndex": "total",
            "key": "total",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": false,
            "optional": true,
            "render": (val, record) => {
                val = val.toFixed(2)
                return <p>£ {val}</p>
            }
        },
        {
            "title": "status",
            "dataIndex": "status",
            "key": "status",
            "showInTable": true,
            "type": "enum",
            "filter": true,
            "secure": false,
            "optional": true,

            "arr": ['initiated', 'completed'],
            'colors': { initiated: 'red', completed: 'green' }
        },

        // {
        //     "title": "Address",
        //     "dataIndex": "address",
        //     "key": "address",
        //     "showInTable": false,
        //     "type": "object",
        //     "filter": false,
        //     "secure": false,
        //     "optional": true,
        //     disableFields: {
        //         index: true,
        //         latitude: true,
        //         longitude: true,
        //         location: true,
        //         locality: true,
        //         county: true,
        //     },
        //     fieldsType: {}


        // },

        // {
        //     "title": "Profile Pic",
        //     "dataIndex": "profilePic",
        //     "key": "profilePic",
        //     "showInTable": false,
        //     "type": "object",
        //     "filter": false,
        //     "secure": false,
        //     "optional": true,
        //     disableFields: {
        //         secure: true,
        //     },
        //     fieldsType: {

        //         url: 'image'

        //     }


        // },
        // {
        //     "title": "Id Proof",
        //     "dataIndex": "idProof",
        //     "key": "idProof",
        //     "showInTable": false,
        //     "type": "object",
        //     "filter": false,
        //     "secure": false,
        //     "optional": true,
        //     disableFields: {
        //         secure: true,
        //     },
        //     fieldsType: {

        //         url: 'pdf'

        //     }


        // },
        // {
        //     "title": "Address Proof",
        //     "dataIndex": "addressProof",
        //     "key": "addressProof",
        //     "showInTable": false,
        //     "type": "object",
        //     "filter": false,
        //     "secure": false,
        //     "optional": true,
        //     disableFields: {
        //         secure: true,
        //     },
        //     fieldsType: {

        //         url: 'pdf'

        //     }


        // },
        // {
        //     "title": "Food Certificate",
        //     "dataIndex": "foodCertificate",
        //     "key": "foodCertificate",
        //     "showInTable": false,
        //     "type": "object",
        //     "filter": false,
        //     "secure": false,
        //     "optional": true,
        //     disableFields: {
        //         secure: true,
        //     },
        //     fieldsType: {

        //         url: 'pdf'

        //     }


        // },
        {

            "title": "From Date",
            "dataIndex": "fromDate",
            "key": "fromDate",
            "showInTable": true,
            "type": "date",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,

            "render": (val) => moment(val).format("DD-MM-YYYY")

        },
        {

            "title": "To Date",
            "dataIndex": "toDate",
            "key": "toDate",
            "showInTable": true,
            "type": "date",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            "optional": true,

            "render": (val) => moment(val).format("DD-MM-YYYY")

        },
        {
            "title": "Action",
            "dataIndex": "action",
            "key": "action",
            "showInTable": true,
            "type": "string",
            "filter": false,
            "secure": false,
            "disableInForm": true,
            disableInView: true,
            "optional": true,
            "render": (val, record) => {

                return (
                    <>
                        {record.status == "initiated" && <Popconfirm
                            title="Are you sure it is payed?"
                            onConfirm={async () => {
                                console.log("clicked", record._id);
                                let res = await helper.updateData({ _id: record._id, "status": "completed" }, "chefpayments")
                                console.log(res)
                                if (res.status === 200) {
                                    window.location.reload();

                                    // helper.message.success('approved successfully')
                                    // await this.getData()
                                    return res
                                }
                                return helper.message.error('error updating data')

                            }}
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                        ><Button type="primary" size="small" style={{ borderColor: "green", color: 'green' }} ghost
                        >Payed</Button></Popconfirm>}

                    </>

                );
            }
        },

        {
            "title": "Orders",
            "dataIndex": "orders",
            "key": "orders",
            "showInTable": false,
            "type": "array",
            "filter": false,
            "secure": false,
            "optional": true,
            disableFields: {
                // _id: true,
                // drop: true,
                // deliverImage: true,
                // orderId: true


            },
            fieldsType: {
                // deliverImage: {
                //     url: 'pdf'
                // },
            },
            render: {
                // drop: (val) => addressJoin(val)
                total: (val) => '£ ' + val,
                date: (val) => moment(val).format('DD-MM-YYYY')

            }


        },
    ]

}
const obj = new ChefPaymentTab()
makeAutoObservable(obj)
export { obj }